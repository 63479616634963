import Store from '../../store'
import ValueObjectService from '../ValueObjectService'

const getPrettyName = (value) => {
  return ValueObjectService.getPrettyName(
    Store.getters['tasrNews/valueObjectStatus'],
    value
  )
}

const getListingIcon = (value) => {
  const config = {
    downloaded: {
      title: 'Nová',
      icon: 'label-inverse'
    },
    failed: {
      title: 'Chyba spracovania',
      icon: 'btn-danger'
    },
    processed: {
      title: 'Prebratá',
      icon: 'btn-green'
    }
  }
  return (config[value]) ? '<span class="label ' + config[value].icon + ' text-uppercase">' +
    '<strong>' + config[value].title + '</strong></span>' : ''
}

export default {
  getPrettyName,
  getListingIcon
}
