<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterTasrNews">
          <div class="row">
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.id" id="filter_id" :label="$t('tasrNews.id')"></app-input>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.headline" id="filter_headline"
                         :label="$t('tasrNews.filter.headline')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-select
                v-model="filter.service"
                :options="valueObjectServiceType"
                :label="$t('tasrNews.filter.service')"
                id="filter_service"
                @change="loadCategoriesByService"
                noEmptyValue
              >
              </app-select>
            </div>
            <div class="col-lg-2 col-md-6">
              <label>{{ $t('tasrNews.filter.category') }}</label>
              <app-multi-select
                v-model="filter.category"
                :options="categories"
                label="title"
                track-by="id"
                id="filter_category"
              >
              </app-multi-select>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                type="date"
                v-model="filter.contentCreatedFrom"
                id="filter_createdAtFrom"
                :label="$t('tasrNews.filter.created_at_from')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                type="date"
                v-model="filter.contentCreatedTo"
                id="filter_createdAtTo"
                :label="$t('tasrNews.filter.created_at_to')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-2">
              <button class="btn btn-success pull-right m-t-30">
                {{ $t('tasrNews.filter.search') }}
              </button>
              <button
                type="button"
                class="btn btn-secondary m-t-30"
                data-test="reset_filter"
                @click="resetFilter"
              >
                {{ $t('buttons.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '../form/inputs/Input'
import TasrNewsFilter from '../../model/TasrNewsFilter'
import Select from '../form/select/Select'
import ValueObjectService from '../../services/ValueObjectService'
import Datepicker from '../form/Datepicker'
import MultiSelect from '../form/select/MultiSelect'

export default {
  name: 'TasrNewsFilter',
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(TasrNewsFilter),
      categories: []
    }
  },
  computed: {
    valueObjectServiceType () {
      return ValueObjectService.getAsArray(this.$store.getters['tasrNews/valueObjectServiceType']).filter(service => {
        if (service.id !== 'none') {
          return service
        }
      })
    }
  },
  components: {
    appInput: Input,
    appSelect: Select,
    appDatepicker: Datepicker,
    appMultiSelect: MultiSelect
  },
  methods: {
    filterTasrNews () {
      this.$store.commit('tasrNews/setPage', 1)
      this.$store.commit('tasrNews/setFilter', this.filter)
      this.$store.dispatch('tasrNews/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(TasrNewsFilter)
      this.$store.commit('tasrNews/setFilter', this.filter)
      this.$store.dispatch('tasrNews/fetch')
    },
    loadCategoriesByService () {
      this.categories = []
      this.filter.category = null
      if (!this.filter.service) {
        return
      }
      this.$store.getters['tasrNewsCategory/all'].forEach(item => {
        if (item.service === this.filter.service) {
          this.categories.push(item)
        }
      })
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['tasrNews/filter']
  }
}
</script>

<style lang="scss">

</style>
