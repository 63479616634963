import Store from '../../store'
import ValueObjectService from '../ValueObjectService'

const getPrettyName = (value) => {
  return ValueObjectService.getPrettyName(
    Store.getters['tasrNews/valueObjectServiceType'],
    value
  )
}

export default {
  getPrettyName
}
